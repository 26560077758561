import { Lookup } from "../../shared/models";

export function getLookupFromQueryParams<T extends Lookup>(
    queryParams: Record<string, any>,
    lookupClass: new (data: Partial<T>) => T
  ): Partial<T> {
    const defaultLookup =  new lookupClass({}); 
    const partialLookup = Object.keys(queryParams).reduce<Partial<T>>((acc, key) => {
      if (queryParams.hasOwnProperty(key) && key in defaultLookup) {
        acc[key as keyof T] = queryParams[key];
      }
      return acc;
    }, {});
  
    return partialLookup;
}

export function getLookupFromLocalStorage<T extends Lookup>(key: string): Partial<T> {
    try {
      return JSON.parse(localStorage.getItem(key) ?? '{}');
    } catch (error) {
      return {};
    }
}

export function convertLookupToQueryParams<T extends Lookup>(
    lookup: T,
    excludeFields: Array<string> = ['skip', 'limit', 'total', 'sort']
  ): Record<string, string> {
    const shouldExclude = (key: string): boolean => excludeFields.includes(key);
    const isValidValue = (value: any): boolean => value != null && value !== '' && !(Array.isArray(value) && !value.length);
    const convertDateToISOString = (date: Date): string => date.toISOString();
    const convertToString = (value: any): string => value?.toString() || String(value);
  
    return Object.keys(lookup).reduce((acc, key) => {
      const value = lookup[key];
  
      if (!shouldExclude(key) && isValidValue(value)) {
        acc[key] = value instanceof Date ? convertDateToISOString(value) : convertToString(value);
      }
  
      return acc;
    }, {});
}