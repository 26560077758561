import { Lookup } from '../../../../shared/models';
import { Operator } from '../../domain/models';

export class LookupWithOperator extends Lookup {
    operator: Operator = null;
  
    constructor(data?: any) {
      super(data);
      if (data) {
        this.operator = this.parseOperator(data.operator);
      }
    }

    protected parseOperator(value: string | Operator): Operator | null {
        if (!value) return null;
        
        // parse JSON when operator taken from query params
        if (typeof value === 'string') {
          try {
            const parsedObject = JSON.parse(value);
            const operator = new Operator();
            operator.id = parsedObject?.id;
            operator.name = parsedObject.name;
            operator.legalName = parsedObject.legalName;
            return operator;
          } catch (error) {
            return null;
          }
        }
      
        if (typeof value === 'object') {
          return value;
        }
      
        return null;
    }
}