import { Organization } from './organization';
import { Role } from './role';
import { RestrictedFunction } from './restricted-function';
import { IPermissions } from './permissions';

export class User {
  id: number;
  userTypeId: number;
  firstName: string;
  lastName: string;
  organization: Organization;
  roles: Array<Role>;
  emailAddress: string;
  viewPermissions: IPermissions;
  logoutUrl: string;
  restrictedFunctions: Array<RestrictedFunction>;

  constructor() {
    this.roles = [];
    this.viewPermissions = {};
    this.restrictedFunctions = [];
  }
}
