export enum OutletNameEnum {
  AIRCRAFT_LIST = 'aircraft-list',
  AIRCRAFT_DETAILS = 'aircraft-details',
  VENDOR_LIST = 'vendor-list',
  VENDOR_DETAILS = 'vendor-details',
  USER_LIST = 'user-list',
  USER_DETAILS = 'user-details',
  ORGANIZATION_DETAILS = 'organization-details',
  CONFIG_LIST = 'config-list',
  CONFIG_DETAILS = 'config-details',
  FLOOR_PLAN_DETAILS = 'floor-plan-details',
}
