import { Component, Input, Output, ViewEncapsulation, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { SystemComponent } from '../../../shared/components';
import * as models from '../../../shared/models';
import { environment } from '../../../../environments/environment';
import { EnvironmentEnum } from '../../../../environments/environment.interface';
import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DebugComponent extends SystemComponent {
  @Input() gitEnvVariables: models.GitEnvVariables;
  @Input() status: models.ApiStatus;
  @Input() devToken: string;
  @Input() isStatusLoading: boolean;
  @Output() statusExpand = new EventEmitter<string>();

  @ViewChildren("panel") public panels: QueryList<ExpansionPanelComponent>;
  
  apiNameList = Object.values(models.ApiEnum).map(String);
  envConfigurations = environment;
  environmentEnum = EnvironmentEnum;

  onApiStatusClick(targetPanel: ExpansionPanelComponent): void {
    if (!targetPanel.expanded) {
      this.statusExpand.emit(targetPanel.title);
    }
    
    this.panels.forEach((panel) => {
      if(panel.title !== targetPanel.title) {
        panel.toggle(false);
      }
    });
  }

  addTokenToUrl(apiUrl: string): string {
    const url = new URL(apiUrl);
    if (this.devToken) {
      url.searchParams.set('token', this.devToken);
    }
    return url.toString();
  }
}
