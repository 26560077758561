import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';
import { Lookup } from '../../../../shared/models';

export const LOAD = '[RM Area Collection] Load';
export const LOAD_SUCCESS = '[RM Area Collection] Load Success';
export const LOAD_FAIL = '[RM Area Collection] Load Fail';
export const ADD_AREA = '[RM Area Collection] Add Area';
export const ADD_AREA_SUCCESS = '[RM Area Collection] Add Area Success';
export const ADD_AREA_FAIL = '[RM Area Collection] Add Area Fail';

export class LoadPayload {
  constructor(public lookup: Lookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<viewModels.ListItem>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class AddAreaAction implements Action {
  readonly type = ADD_AREA;

  constructor(public payload: AddAreaPayload) {}
}

export class AddAreaPayload {
  constructor(public name: string) {}
}

export class AddAreaSuccessAction implements Action {
  readonly type = ADD_AREA_SUCCESS;

  constructor(public payload: AddAreaSuccessPayload) {}
}

export class AddAreaSuccessPayload {
  constructor(public item: models.Area) {}
}

export class AddAreaFailAction implements Action {
  readonly type = ADD_AREA_FAIL;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | AddAreaAction
  | AddAreaSuccessAction
  | AddAreaFailAction;
