import { Inject, Injectable } from '@angular/core';
import * as models from '../../models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as apiStatusContracts from './contracts'
import * as coreTypes from '../../../core/types';


@Injectable()
export class ApiStatusService implements apiStatusContracts.IApiStatusService {
  apiEnum = models.ApiEnum;

  constructor(
    private http: HttpClient,
    @Inject(coreTypes.SERVICE_TOKEN) private configService: coreTypes.IConfigService
  ) {}

  getApiStatus(request: string): Observable<models.ApiStatus> {
    let apiUrl = '';
    switch(request) {
      case this.apiEnum.OPERATOR_API: {
        apiUrl = this.configService.get('OperatorApiServiceEndpoint');
        break;
      }
      case this.apiEnum.AIRCRAFT_API: {
        apiUrl = this.configService.get('AircraftServiceEndpoint');
        break;
      }
      case this.apiEnum.SOURCING_API: {
        apiUrl = this.configService.get('SourcingApiServiceEndpoint');
        break;
      }
      case this.apiEnum.EMAIL_SERVICE_API: {
        apiUrl = this.configService.get('EmailServiceEndpoint');
        break;
      }
      default: {
        return of(null);
      }
    }
    return this.http.get<any>(`${apiUrl}/status`).pipe(
      map(serviceResponse => {
        const apiStatus = new models.ApiStatus();
        const {['xo-libraries']: xoLibraries, ...status} = serviceResponse;
        apiStatus.xoLibraries = xoLibraries;
        apiStatus.status = status;
        apiStatus.apiUrl = apiUrl ? `${apiUrl}/index.html`: null;
        return apiStatus;
      })
    );
  }
}
