import * as models from '../../models';
import { Observable } from 'rxjs';
import { InjectionToken } from '@angular/core';

export interface IUserService {
  getUser(request: GetUserRequest): Observable<GetUserResponse>;
  setUserMenuPinPreference(request: SetUserMenuPinRequest): Observable<SetUserMenuPinResponse>;
  getUserRestrictedFunctions(): Observable<GetUserRestrictedFunctionsResponse>
  signOut(request: SignOutRequest): Observable<SignOutResponse>;
}

export const USER_SERVICE_TOKEN = new InjectionToken('Shared.UserService');

export class SetUserMenuPinRequest {
  menuPin: number;
}

export class SetUserMenuPinResponse {
}

export class GetUserRequest {
}

export class GetUserResponse {
  entity: models.User;
  environment: string;
  menuPin: number;
}

export class SignOutRequest {
  isLegacyAuthInterceptorError?: boolean;
}

export class SignOutResponse {}

export class GetUserRestrictedFunctionsResponse {
  restrictedFunctions: Array<models.RestrictedFunction>;
}
