import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const RESET = '[Marketplace.FlightRequestList] Reset';
export const LOAD = '[Marketplace.FlightRequestList] Load';
export const LOAD_SUCCESS = '[Marketplace.FlightRequestList] Load Success';
export const LOAD_FAIL = '[Marketplace.FlightRequestList] Load Fail';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class LoadPayload {
  constructor(public lookup: viewModels.FlightRequestLookupCriteria) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(
    public items: Array<models.FlightRequest>,
    public total: number
  ) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = ResetAction | LoadAction | LoadSuccessAction | LoadFailAction;
