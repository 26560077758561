import * as models from '../../domain/models';

import * as actions from '../actions/my-sourcing-request-list';
import * as viewModels from '../../view/view-models';
import * as _ from 'lodash';

export interface State {
  lookup: viewModels.SourcingRequestLookup;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.SourcingRequest>;
}

const initialState: State = {
  lookup: new viewModels.SourcingRequestLookup(),
  isLoaded: false,
  isLoading: false,
  items: [],
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.RESET: {
      return { ...initialState };
    }

    case actions.LOAD: {
      return {
        ...state,
        lookup: _.cloneDeep(action.payload.lookup),
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const items = _.cloneDeep(action.payload.items);
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = action.payload.total;
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        items,
        lookup
      };
    }

    default: {
      return state;
    }
  }
}

export const getCriteria = (state: State) => state.lookup;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
