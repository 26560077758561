import { Lookup } from '../../../../shared/models';
import { SortDescriptor } from '@progress/kendo-data-query';

export class SentEmailsLookup extends Lookup {
  from: string = null;
  to: string = null;
  cc: string = null;
  bcc: string = null;
  replyTo: string = null;
  skipSending: boolean = null;
  limit = 10;
  sort: Array<SortDescriptor> = [{ field: 'sent', dir: 'desc' }];

  constructor(data?: any) {
    super(data);
    if (data) {
      this.from = this.parseString(data.from);
      this.to = this.parseString(data.to);
      this.cc = this.parseString(data.cc);
      this.bcc = this.parseString(data.bcc);
      this.replyTo = this.parseString(data.replyTo);
      this.skipSending = this.parseBoolean(data.skipSending);
    }
  }
}
