import { Lookup } from '../../../../shared/models';

export class FlightRequestLookupCriteria extends Lookup {
  salesAdvisor: string = null;
  from: string = null;
  to: string = null;
  departureDate: Date = null;
  client: string = null;
  aircraftCategory: number = null;

  constructor(data?: any) {
    super(data);

    if (data) {
      this.salesAdvisor = this.parseString(data.salesAdvisor);
      this.from = this.parseString(data.from);
      this.to =  this.parseString(data.to);
      this.departureDate = this.parseDate(data.departureDate);
      this.client = this.parseString(data.client);
      this.aircraftCategory = this.parseNumber(data.aircraftCategory);
    }
  }
}
