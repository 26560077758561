import * as models from '../../domain/models';
import * as actions from '../actions/aircraft-floor-plan-detail';
import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  initialItem: models.GenericFloorPlan;
  editedItem: models.GenericFloorPlan;
  pristine: boolean;
  editMode: boolean;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  initialItem: new models.GenericFloorPlan(),
  editedItem: new models.GenericFloorPlan(),
  pristine: true,
  editMode: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    }

    case actions.RESET: {
      return { ...initialState };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        pristine: true,
        initialItem: _.cloneDeep(action.payload.item),
        editedItem: _.cloneDeep(action.payload.item),
      };
    }

    case actions.SET: {
      let pristine = _.cloneDeep(state.pristine);
      const compare1 = _.cloneDeep(state.editedItem);
      const compare2 = _.cloneDeep(action.payload.item);
      delete compare1.deleted;
      delete compare2.deleted;
      if (!_.isEqual(compare1, compare2)) {
        pristine = false;
      }

      return {
        ...state,
        pristine,
        editedItem: _.cloneDeep(action.payload.item),
      };
    }

    case actions.BYPASS_LOAD: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.CANCEL: {
      return {
        ...state,
        pristine: true,
        editedItem: _.cloneDeep(state.initialItem),
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...initialState,
        isLoaded: true,
      };
    }

    case actions.SAVE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.SAVE_SUCCESS: {
      return {
        ...state,
        pristine: true,
        isLoading: false,
        initialItem: _.cloneDeep(action.payload.item),
        editedItem: _.cloneDeep(action.payload.item),
      };
    }

    case actions.SAVE_FAIL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case actions.TOGGLE_EDIT_MODE: {
      return {
        ...state,
        editMode: !state.editMode,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItem = (state: State) => state.editedItem;
export const getPristine = (state: State) => state.pristine;
export const getIsEditMode = (state: State) => state.editMode;
