import { Operator } from './operator';
import { VendorOrganizationUser } from './vendor-organization-user';

export class ChangeLogItem {
  modificationDate: Date;
  email: string;
  type: string;
  action: string;
  changes: Array<any>;
  displayedType: string;
  currentOperator: Operator;
  currentUser: VendorOrganizationUser;
}
