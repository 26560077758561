import * as viewModels from '../../view/view-models';
import * as models from '../../domain/models';
import * as actions from '../actions/floor-plans-collection';

import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.GenericFloorPlan>;
  selectedItems: Array<models.GenericFloorPlan>;
  lookup: viewModels.FloorPlansLookup;
  isImportStatusLoaded: boolean;
  isImportStatusLoading: boolean;
  importStatus: boolean;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
  selectedItems: [],
  lookup: new viewModels.FloorPlansLookup(),
  isImportStatusLoaded: false,
  isImportStatusLoading: false,
  importStatus: null,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        lookup: _.cloneDeep(action.payload.lookup),
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const items = _.cloneDeep(action.payload.items);
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = action.payload.total;
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        lookup,
        items,
      };
    }

    case actions.RESET: {
      return {
        ...initialState,
      }
    }

    case actions.LOAD_FAIL: {
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...initialState,
        isLoaded: true,
        lookup,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getLookup = (state: State) => state.lookup;
