import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';
import { Lookup } from '../../../../shared/models';

export const LOAD = '[RM Market Collection] Load';
export const LOAD_SUCCESS = '[RM Market Collection] Load Success';
export const LOAD_FAIL = '[RM Market Collection] Load Fail';
export const ADD_MARKET = '[RM Market Collection] Add Market';
export const ADD_MARKET_SUCCESS = '[RM Market Collection] Add Market Success';
export const ADD_MARKET_FAIL = '[RM Market Collection] Add Market Fail';

export class LoadPayload {
  constructor(public lookup: Lookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public items: Array<viewModels.ListItem>, public total: number) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class AddMarketAction implements Action {
  readonly type = ADD_MARKET;

  constructor(public payload: AddMarketPayload) {}
}

export class AddMarketPayload {
  constructor(public item: viewModels.AddMarket) {}
}

export class AddMarketSuccessAction implements Action {
  readonly type = ADD_MARKET_SUCCESS;

  constructor(public payload: AddMarketSuccessPayload) {}
}

export class AddMarketSuccessPayload {
  constructor(public item: models.Market) {}
}

export class AddMarketFailAction implements Action {
  readonly type = ADD_MARKET_FAIL;
}


export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | AddMarketAction
  | AddMarketSuccessAction
  | AddMarketFailAction;
