import { OperatorApprovalStatusEnum } from "../../domain/models";
import { Lookup } from '../../../../shared/models';

export class VendorLookup extends Lookup {
  active: boolean = null;
  prospectusApproved: boolean = null;
  automatedSourcingForGuaranteed: boolean = null;
  automatedSourcingForNonGuaranteed: boolean = null;
  aircraftsRegistrationCountryCode: string = null;
  operatorApprovalStatus: OperatorApprovalStatusEnum = null;
  regionIds: Array<number> = [];

  constructor(data?: any) {
    super(data);
    if (data) {
      this.active = this.parseBoolean(data.active) ?? true;
      this.prospectusApproved = this.parseBoolean(data.prospectusApproved);
      this.automatedSourcingForGuaranteed = this.parseBoolean(data.automatedSourcingForGuaranteed);
      this.automatedSourcingForNonGuaranteed = this.parseBoolean(data.automatedSourcingForNonGuaranteed);
      this.aircraftsRegistrationCountryCode = this.parseString(data.aircraftsRegistrationCountryCode);
      this.operatorApprovalStatus = this.parseEnumValue(data.operatorApprovalStatus, OperatorApprovalStatusEnum);
      this.regionIds = this.parseArray<number>(data.regionIds);
    }
  }
}
