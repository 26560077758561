import * as actions from '../actions/sent-emails';
import { Email } from '../../domain/models';
import { SortDescriptor } from '@progress/kendo-data-query';
import * as viewModels from '../../view/view-models';
import * as _ from 'lodash';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: Array<Email>;
  sort: Array<SortDescriptor>;
  lookup: viewModels.SentEmailsLookup;
  totalItems: number;
  emailBody: string;
  emailBodyLoaded: boolean;
  emailBodyLoading: boolean;
}

const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  sort: [{ field: 'sent', dir: 'desc' }],
  totalItems: null,
  emailBody: null,
  emailBodyLoaded: false,
  emailBodyLoading: false,
  lookup: new viewModels.SentEmailsLookup(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_SENT_EMAILS: {
      return {
        ...state,
        loading: true,
        lookup: _.cloneDeep(action.payload.lookup),
        loaded: false,
      };
    }

    case actions.LOAD_SENT_EMAILS_SUCCESS: {
      const items = _.cloneDeep(action.payload.emails);
      const totalItems = _.cloneDeep(action.payload.totalItems);
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = action.payload.totalItems;
      return {
        ...state,
        loaded: true,
        loading: false,
        items,
        totalItems,
        lookup,
      };
    }

    case actions.LOAD_SENT_EMAILS_FAILURE: {
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...state,
        loaded: true,
        loading: false,
        lookup,
      };
    }

    case actions.LOAD_EMAIL_BODY: {
      return {
        ...state,
        emailBodyLoaded: false,
        emailBodyLoading: true,
      };
    }

    case actions.LOAD_EMAIL_BODY_SUCCESS: {
      return {
        ...state,
        emailBodyLoaded: true,
        emailBodyLoading: false,
        emailBody: action.payload.body,
      };
    }

    case actions.LOAD_EMAIL_BODY_FAILURE: {
      return {
        ...state,
        emailBodyLoaded: false,
        emailBodyLoading: false,
      };
    }

    case actions.RESET: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getItems = (state: State) => state.items;
export const getTotalItems = (state: State) => state.totalItems;
export const getLookup = (state: State) => state.lookup;
export const getEmailBody = (state: State) => state.emailBody;
export const getEmailBodyLoaded = (state: State) => state.emailBodyLoaded;
export const getEmailBodyLoading = (state: State) => state.emailBodyLoading;
