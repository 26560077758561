export class Lookup {
  skip = 0;
  limit = 15;
  total = 0;
  searchTerm: string = null;

  constructor(data?: any) {
    if (data) {
      this.searchTerm = this.parseString(data.searchTerm);
    }
  }

  protected parseBoolean(value: any, defaultValue: boolean = null): boolean {
    if (value === null || value === undefined) {
      return defaultValue;
    }
    return value === 'true' || value === true;
  }

  protected parseString(value: any): string {
    return value !== undefined && value !== null ? String(value) : null;
  }

  protected parseNumber(value: any): number {
    return value !== undefined && value !== null ? Number(value) : null;
  }

  protected parseDate(value: any): Date | null {
    const date = value ? new Date(value) : null;
    return !isNaN(date?.getTime()) ? date : null;
  }

  protected parseArray<T extends string | number>(value: string | Array<T> | null): Array<T> {
    if (!value) return [];

    // Parse JSON when value is a stringified array
    if (typeof value === 'string') {
        try {
            const parsed = JSON.parse(value);
            if (Array.isArray(parsed)) {
              return parsed.map((item) => this.convertToType<T>(item));
            } 
        } catch {
            return value.split(',').map((item) => this.convertToType<T>(item));
        }
    }

    if (Array.isArray(value)) {
      return value.map((item) => this.convertToType<T>(item));
    } 

    return [];
}

private convertToType<T extends string | number>(value: any): T {
    return (typeof value === 'number' ? value : isNaN(Number(value)) ? value : Number(value)) as T;
}

  protected parseEnumValue<T>(value: any, enumType: T, defaultValue: T[keyof T] = null): T[keyof T] {
    if (value === null || value === undefined) {
      return defaultValue;
    }

    const enumValues = Object.values(enumType);
    if (enumValues.includes(value)) {
      return value as T[keyof T];
    }

    const enumKeys = Object.keys(enumType);
    if (enumKeys.includes(value)) {
      return enumType[value as keyof T];
    }

    return defaultValue;
  }
}
