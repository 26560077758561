export class GenericFloorPlan {
  id: number;
  createTime: Date;
  updateTime: Date;
  name: string;
  comment: string;
  active: boolean;
  thumbnailImageUrl: string;
  imageUrl: string;
  width: number;
  height: number;
}
