import { SortDescriptor } from '@progress/kendo-data-query';
import { Lookup } from '../../../../shared/models';

export class FloorPlansLookup extends Lookup {
  active: boolean = true;
  sort: Array<SortDescriptor> = [{ field: 'name', dir: 'asc' }];

  constructor(data?: any) {
    super(data);
    if (data) {
      this.active = this.parseBoolean(data.active) ?? true;
    }
  }
}

