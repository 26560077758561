import { UserAccount } from "./user-account";

export class OPUser {
  userId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  permissions: Array<string>;
  lastLoginDate: Date;
  role: string;
  syncTime: Date;
  accounts: Array<UserAccount> = [];

  getUserId(): string {
    return this.userId;
  }

  setUserId(userId: string): OPUser {
    this.userId = userId;
    return this;
  }

  getFirstName(): string {
    return this.firstName;
  }

  setFirstName(firstName: string): OPUser {
    this.firstName = firstName;
    return this;
  }

  getLastName(): string {
    return this.lastName;
  }

  setLastName(lastName: string): OPUser {
    this.lastName = lastName;
    return this;
  }

  getEmailAddress(): string {
    return this.emailAddress;
  }

  setEmailAddress(emailAddress: string): OPUser {
    this.emailAddress = emailAddress;
    return this;
  }

  getPhoneNumber(): string {
    return this.phoneNumber;
  }

  setPhoneNumber(phoneNumber: string): OPUser {
    this.phoneNumber = phoneNumber;
    return this;
  }

  getPermissions(): Array<string> {
    return this.permissions;
  }

  setPermissions(permissions: Array<string>): OPUser {
    this.permissions = permissions;
    return this;
  }

  getLastLoginDate(): Date {
    return this.lastLoginDate;
  }

  setLastLoginDate(lastLoginDate: Date): OPUser {
    this.lastLoginDate = lastLoginDate;
    return this;
  }

  getRole(): string {
    return this.role;
  }

  setRole(role: string): OPUser {
    this.role = role;
    return this;
  }

  getSyncTime(): Date {
    return this.syncTime;
  }

  setSyncTime(syncTime: Date): OPUser {
    this.syncTime = syncTime;
    return this;
  }

  getAccounts(): Array<UserAccount> {
    return this.accounts;
  }

  setAccounts(accounts: Array<UserAccount>): OPUser {
    this.accounts = accounts;

    return this;
  }
}
