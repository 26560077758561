import { createSelector } from 'reselect';
import { ActionReducer, combineReducers } from '@ngrx/store';

import * as fromOneWayQuoteCollection from './one-way-quote-collection';
import * as fromOneWayQuoteStatusCollection from './one-way-quote-status-collection';
import * as fromOperatorCollection from './operator-collection';
import * as fromAircraftCategoryCollection from './aircraft-category-collection';
import * as fromFlightRequestList from '../../application/reducers/flight-request-list';
import * as fromFlightRequest from '../../application/reducers/flight-request';
import * as fromFlightPreviewCollection from '../../application/reducers/flight-preview-collection';
import * as fromSalesAgentCollection from '../../application/reducers/sales-agent-collection';
import * as fromMemberShipStatus from './membership-status';
import * as fromNewFlightRequest from './new-flight-request';
import * as fromSourcingRequestList from './sourcing-request-list';
import * as fromTravelReasonCollection from './travel-reason-collection';
import * as fromSpecialRequirementCollection from './special-requirement-collection';


export interface State {
  oneWayQuoteCollection: fromOneWayQuoteCollection.State;
  oneWayQuoteStatusCollection: fromOneWayQuoteStatusCollection.State;
  operatorCollection: fromOperatorCollection.State;
  flightRequestList: fromFlightRequestList.State;
  flightRequest: fromFlightRequest.State;
  aircraftCategoryCollection: fromAircraftCategoryCollection.State;
  flightPreviewCollection: fromFlightPreviewCollection.State;
  salesAgentCollection: fromSalesAgentCollection.State;
  membershipStatus: fromMemberShipStatus.State;
  newFlightRequest: fromNewFlightRequest.State;
  sourcingRequestList: fromSourcingRequestList.State;
  travelReasonCollection: fromTravelReasonCollection.State;
  specialRequirementCollection: fromSpecialRequirementCollection.State;
}

const reducers = {
  oneWayQuoteCollection: fromOneWayQuoteCollection.reducer,
  oneWayQuoteStatusCollection: fromOneWayQuoteStatusCollection.reducer,
  operatorCollection: fromOperatorCollection.reducer,
  flightRequestList: fromFlightRequestList.reducer,
  flightRequest: fromFlightRequest.reducer,
  aircraftCategoryCollection: fromAircraftCategoryCollection.reducer,
  flightPreviewCollection: fromFlightPreviewCollection.reducer,
  salesAgentCollection: fromSalesAgentCollection.reducer,
  membershipStatus: fromMemberShipStatus.reducer,
  newFlightRequest: fromNewFlightRequest.reducer,
  sourcingRequestList: fromSourcingRequestList.reducer,
  travelReasonCollection: fromTravelReasonCollection.reducer,
  specialRequirementCollection: fromSpecialRequirementCollection.reducer,
};

const combinedReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): any {
  return combinedReducer(state, action);
}

export const getOneWayQuoteCollectionState = (state: any) =>
  state.marketplace.oneWayQuoteCollection;
export const getOneWayQuoteCollectionCriteria = createSelector(
  getOneWayQuoteCollectionState,
  fromOneWayQuoteCollection.getCriteria
);
export const getOneWayQuoteCollectionCurrentPage = createSelector(
  getOneWayQuoteCollectionState,
  fromOneWayQuoteCollection.getCurrentPage
);
export const getOneWayQuoteCollectionIsLoaded = createSelector(
  getOneWayQuoteCollectionState,
  fromOneWayQuoteCollection.getIsLoaded
);
export const getOneWayQuoteCollectionIsLoading = createSelector(
  getOneWayQuoteCollectionState,
  fromOneWayQuoteCollection.getIsLoading
);
export const getOneWayQuoteCollectionItems = createSelector(
  getOneWayQuoteCollectionState,
  fromOneWayQuoteCollection.getItems
);
export const getOneWayQuoteCollectionItemsPerPage = createSelector(
  getOneWayQuoteCollectionState,
  fromOneWayQuoteCollection.getItemsPerPage
);
export const getOneWayQuoteCollectionItemsTotalCount = createSelector(
  getOneWayQuoteCollectionState,
  fromOneWayQuoteCollection.getItemsTotalCount
);

export const getOneWayQuoteStatusCollectionState = (state: any) =>
  state.marketplace.oneWayQuoteStatusCollection;
export const getOneWayQuoteStatusCollectionIsLoaded = createSelector(
  getOneWayQuoteStatusCollectionState,
  fromOneWayQuoteStatusCollection.getIsLoaded
);
export const getOneWayQuoteStatusCollectionIsLoading = createSelector(
  getOneWayQuoteStatusCollectionState,
  fromOneWayQuoteStatusCollection.getIsLoading
);
export const getOneWayQuoteStatusCollectionItems = createSelector(
  getOneWayQuoteStatusCollectionState,
  fromOneWayQuoteStatusCollection.getItems
);

export const getOperatorCollectionState = (state: any) => state.marketplace.operatorCollection;
export const getOperatorCollectionIsLoaded = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getIsLoaded
);
export const getOperatorCollectionIsLoading = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getIsLoading
);
export const getOperatorCollectionItems = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getItems
);

export const getAircraftCategoryCollectionState = (state: any) =>
  state.marketplace.aircraftCategoryCollection;
export const getAircraftCategoryCollectionLoaded = createSelector(
  getAircraftCategoryCollectionState,
  fromAircraftCategoryCollection.getLoaded
);
export const getAircraftCategoryCollectionLoading = createSelector(
  getAircraftCategoryCollectionState,
  fromAircraftCategoryCollection.getLoading
);
export const getAircraftCategoryCollection = createSelector(
  getAircraftCategoryCollectionState,
  fromAircraftCategoryCollection.getItems
);

export const getFlightRequestListState = (state: any) => state.marketplace.flightRequestList;
export const getFlightRequestListIsLoaded = createSelector(
  getFlightRequestListState,
  fromFlightRequestList.getIsLoaded
);
export const getFlightRequestListIsLoading = createSelector(
  getFlightRequestListState,
  fromFlightRequestList.getIsLoading
);
export const getFlightRequestListFlightRequestLookupCriteria = createSelector(
  getFlightRequestListState,
  fromFlightRequestList.getLookup
);
export const getFlightRequestListItems = createSelector(
  getFlightRequestListState,
  fromFlightRequestList.getItems
);


export const getFlightRequestState = (state: any) => state.marketplace.flightRequest;
export const getFlightRequest = createSelector(
  getFlightRequestState,
  fromFlightRequest.getFlightRequest
);
export const getFlightRequestLoaded = createSelector(
  getFlightRequestState,
  fromFlightRequest.getLoaded
);
export const getFlightRequestLoading = createSelector(
  getFlightRequestState,
  fromFlightRequest.getLoading
);
export const getSourcingRequestCreating = createSelector(
  getFlightRequestState,
  fromFlightRequest.getSourcingRequestCreating
);

export const getFlightPreviewCollectionState = (state: any) => state.marketplace.flightPreviewCollection;
export const getFlightPreviewCollectionLoading = createSelector(getFlightPreviewCollectionState,
  fromFlightPreviewCollection.getFlightPreviewLoading);
export const getFlightPreviewCollectionItems = createSelector(getFlightPreviewCollectionState,
  fromFlightPreviewCollection.getFlightPreviewItems);

export const getSalesAgentCollectionState = (state: any) => state.marketplace.salesAgentCollection;
export const getSalesAgentCollectionLoaded = createSelector(
  getSalesAgentCollectionState,
  fromSalesAgentCollection.getLoaded
);
export const getSalesAgentCollectionLoading = createSelector(
  getSalesAgentCollectionState,
  fromSalesAgentCollection.getLoading
);
export const getSalesAgentCollection = createSelector(
  getSalesAgentCollectionState,
  fromSalesAgentCollection.getItems
);

export const getMembershipStatusState = (state: any) => state.marketplace.membershipStatus;
export const getMembershipStatusLoading = createSelector(
  getMembershipStatusState,
  fromMemberShipStatus.getLoading
);
export const getMembershipStatusLoaded = createSelector(
  getMembershipStatusState,
  fromMemberShipStatus.getLoaded
);
export const getMembershipStatus = createSelector(
  getMembershipStatusState,
  fromMemberShipStatus.getMembershipStatus
);

export const getNewFlightRequestState = (state: any) => state.marketplace.newFlightRequest;
export const getNewFlightRequestLoading = createSelector(
  getNewFlightRequestState,
  fromNewFlightRequest.getLoading
);

export const getSourcingRequestListState = (state: any) => state.marketplace.sourcingRequestList;
export const getSourcingRequestListItems = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getItems
);

export const getTravelReasonCollectionState = (state: any) =>
  state.marketplace.travelReasonCollection;
export const getTravelReasonCollectionLoaded = createSelector(
  getTravelReasonCollectionState,
  fromTravelReasonCollection.getLoaded
);
export const getTravelReasonCollectionLoading = createSelector(
  getTravelReasonCollectionState,
  fromTravelReasonCollection.getLoading
);
export const getTravelReasonCollection = createSelector(
  getTravelReasonCollectionState,
  fromTravelReasonCollection.getItems
);

export const getSpecialRequirementCollectionState = (state: any) =>
  state.marketplace.specialRequirementCollection;
export const getSpecialRequirementCollectionLoaded = createSelector(
  getSpecialRequirementCollectionState,
  fromSpecialRequirementCollection.getLoaded
);
export const getSpecialRequirementCollectionLoading = createSelector(
  getSpecialRequirementCollectionState,
  fromSpecialRequirementCollection.getLoading
);
export const getSpecialRequirementCollection = createSelector(
  getSpecialRequirementCollectionState,
  fromSpecialRequirementCollection.getItems
);
