import { SortDescriptor } from '@progress/kendo-data-query';
import { LookupWithOperator } from './lookup-with-operator';

export class UsersLookup extends LookupWithOperator {
  active: boolean = true;
  sort: Array<SortDescriptor> = [{dir: 'asc', field: 'firstName'}];
  permission: string = null;

  constructor(data?: any) {
    super(data);
    if (data) {
      this.active = this.parseBoolean(data.active) ?? true;
      this.permission = this.parseString(data.permission);
    }
  }
}
