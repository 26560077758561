import { Action } from '@ngrx/store';
import { Email } from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const LOAD_SENT_EMAILS = '[RM Vendor Sent Email Collection] Load Sent Emails';
export const LOAD_SENT_EMAILS_FAILURE =
  '[RM Vendor Sent Email Collection] Load Sent Emails Failure';
export const LOAD_SENT_EMAILS_SUCCESS =
  '[RM Vendor Sent Email Collection] Load Sent Emails Success';
export const LOAD_EMAIL_BODY = '[RM Vendor Sent Email Collection] Load Emails Body';
export const LOAD_EMAIL_BODY_FAILURE = '[RM Vendor Sent Email Collection] Load Emails Body Failure';
export const LOAD_EMAIL_BODY_SUCCESS = '[RM Vendor Sent Email Collection] Load Emails Body Success';
export const RESET = '[RM Vendor Sent Email Collection] Reset';

export class LoadPayload {
  constructor(public lookup: viewModels.SentEmailsLookup) {}
}

export class LoadSentEmailsAction implements Action {
  readonly type = LOAD_SENT_EMAILS;

  constructor(public payload: LoadPayload) {}
}

export class LoadSentEmailsFailureAction implements Action {
  readonly type = LOAD_SENT_EMAILS_FAILURE;
}

export class LoadSentEmailsSuccessPayload {
  emails: Array<Email>;
  totalItems: number;
}

export class LoadSentEmailsSuccessAction implements Action {
  readonly type = LOAD_SENT_EMAILS_SUCCESS;

  constructor(public payload: LoadSentEmailsSuccessPayload) {}
}

export class LoadEmailBodyAction implements Action {
  readonly type = LOAD_EMAIL_BODY;

  constructor(public payload: string) {}
}

export class LoadEmailBodyFailureAction implements Action {
  readonly type = LOAD_EMAIL_BODY_FAILURE;
}

export class LoadEmailBodySuccessPayload {
  body: string;
}

export class LoadEmailBodySuccessAction implements Action {
  readonly type = LOAD_EMAIL_BODY_SUCCESS;

  constructor(public payload: LoadEmailBodySuccessPayload) {}
}

export class ResetAction implements Action {
  readonly type = RESET;
}

export type Actions =
  | LoadSentEmailsAction
  | LoadSentEmailsFailureAction
  | LoadSentEmailsSuccessAction
  | LoadEmailBodyAction
  | LoadEmailBodySuccessAction
  | LoadEmailBodyFailureAction
  | ResetAction;
