import { Lookup } from "../../../../shared/models";
import { OfferTypeEnum, RequestStatusEnum } from "../../domain/models";

export class SourcingRequestLookup extends Lookup {
  owner: string = null;
  from: string = null;
  to: string = null;
  departureDate: Date = null;
  pods: Array<string> = [];
  client: string = null;
  status: RequestStatusEnum = null;
  sendToXOMarketplace: boolean = null;
  type: OfferTypeEnum = null;
  flightRequestId: number = null;

  constructor(data?: any) {
    super(data);

    if (data) {
      this.owner = this.parseString(data.owner);
      this.from = this.parseString(data.from);
      this.to =  this.parseString(data.to);
      this.departureDate = this.parseDate(data.departureDate);
      this.pods = this.parseArray<string>(data.pods);
      this.client = this.parseString(data.client);
      this.status = this.parseEnumValue(data.status, RequestStatusEnum);
      this.sendToXOMarketplace= this.parseBoolean(data.sendToXOMarketplace);
      this.type = this.parseEnumValue(data.type, OfferTypeEnum);
      this.flightRequestId = this.parseNumber(data.flightRequestId);
    }
  }
}
