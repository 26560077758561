import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM Aircraft Floor Plan Detail] Load';
export const LOAD_SUCCESS = '[RM Aircraft Floor Plan Detail] Load Success';
export const LOAD_FAIL = '[RM Aircraft Floor Plan Detail] Load Fail';
export const RESET = '[RM Aircraft Floor Plan Detail] Reset';
export const SET = '[RM Aircraft Floor Plan Detail] Set';
export const SAVE = '[RM Aircraft Floor Plan Detail] Save';
export const SAVE_SUCCESS = '[RM Aircraft Floor Plan Detail] Save Success';
export const SAVE_FAIL = '[RM Aircraft Floor Plan Detail] Save Fail';
export const TOGGLE_EDIT_MODE = '[RM Aircraft Floor Plan Detail] Toggle Edit Mode';
export const BYPASS_LOAD = '[RM Aircraft Floor Plan Detail] Bypass Load';
export const CANCEL = '[RM Aircraft Floor Plan Detail] Cancel';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SetPayload {
  constructor(public item: models.GenericFloorPlan) {}
}

export class SetAction implements Action {
  readonly type = SET;

  constructor(public payload: SetPayload) {}
}

export class CancelAction implements Action {
  readonly type = CANCEL;
}

export class LoadPayload {
  constructor(public id: number) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.GenericFloorPlan) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SaveAction implements Action {
  readonly type = SAVE;
}

export class SaveSuccessPayload {
  constructor(public item: models.GenericFloorPlan) {}
}

export class SaveSuccessAction implements Action {
  readonly type = SAVE_SUCCESS;

  constructor(public payload: SaveSuccessPayload) {}
}

export class SaveFailAction implements Action {
  readonly type = SAVE_FAIL;
}

export class ValidatePayload {
  skip: boolean;
}

export class ToggleEditModeAction implements Action {
  readonly type = TOGGLE_EDIT_MODE;
}

export class BypassAction implements Action {
  readonly type = BYPASS_LOAD;
}

export type Actions =
  | ResetAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SetAction
  | CancelAction
  | SaveAction
  | SaveSuccessAction
  | SaveFailAction
  | ToggleEditModeAction
  | BypassAction;
