import { Agreement } from './agreement';
import { EulaAgreement } from './eula-agreement';
import { VendorOrganizationUser } from './vendor-organization-user';

export class VendorOrganization {
  operatorId: string = null;
  operatorIdString: string = null;
  id: number;
  addressLine1: string = null;
  addressLine2: string = null;
  city: string = null;
  postalCode: string = null;
  stateProvince: string = null;
  users: Array<VendorOrganizationUser> = [];
  cvpAgreement: Agreement = null;
  eulaAgreement: EulaAgreement = null;
}
