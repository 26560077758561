import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';

export const RESET = '[Sourcing.SourcingRequestList] Reset';
export const LOAD = '[Sourcing.SourcingRequestList] Load';
export const LOAD_SUCCESS = '[Sourcing.SourcingRequestList] Load Success';
export const LOAD_FAIL = '[Sourcing.SourcingRequestList] Load Fail';
export const SET_PODS = '[Sourcing.SourcingRequestList] Set Pods';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class LoadPayload {
  constructor(public lookup: viewModels.SourcingRequestLookup) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(
    public items: Array<models.SourcingRequest>,
    public total: number
  ) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SetAction implements Action {
  readonly type = SET_PODS;

  constructor(public payload: Array<string>) {}
}

export type Actions = ResetAction 
  | LoadAction 
  | LoadSuccessAction 
  | LoadFailAction
  | SetAction;
