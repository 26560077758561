import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';

import * as contracts from './contracts';
import * as mapper from './mapper/user-mapper.service';
import * as helpers from '../../helpers';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IConfigService } from '../config/contracts';
import { environment } from '../../../../environments/environment';
import { Apollo, ApolloBase, gql } from 'apollo-angular';
import * as _ from 'lodash';

@Injectable()
export class UserService implements contracts.IUserService {
  private operatorApollo: ApolloBase;
  private JET_SALES_PATH: string;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private cookieService: CookieService,
    private configService: IConfigService
  ) {
    this.operatorApollo = this.apollo.use('operatorApollo');
    this.JET_SALES_PATH = this.configService.get('JetSalesServiceEndpoint');
  }

  getUser(request: contracts.GetUserRequest): Observable<contracts.GetUserResponse> {
    const menuPin = this.cookieService.get('JetStudio.menuPinPreference');
    return this.operatorApollo.query<any>({
      query: gql(`query getCurrentUser {
          getCurrentUser {
              permissions
              firstName
              lastName
              userId
              userTypeId
              emailAddress
              organizationId
          }
      }`),
      fetchPolicy: 'network-only',
    }).pipe(
      map((svcJsonResp: any) => {
        const response = new contracts.GetUserResponse();
        if (svcJsonResp) {
          response.entity = mapper.UserMapperService.mapUserDtoToInternal(svcJsonResp?.data?.getCurrentUser);
        }
        sessionStorage.setItem('id', String(response.entity.id));
        response.environment = _.get(environment, 'serverEnvironment', 'prod');
        response.menuPin = menuPin !== undefined && menuPin !== null ? Number(menuPin) : 0;

        return response;
      })
    );
  }

  getUserRestrictedFunctions(): Observable<contracts.GetUserRestrictedFunctionsResponse> {
    const requestUrl = `${this.JET_SALES_PATH}/v1.0/security/get-security-data`;
    return this.http.get<{ data?: { restrictedFunctions?: any[] } }>(requestUrl).pipe(
      map(({ data }) => {
        const response = new contracts.GetUserRestrictedFunctionsResponse();
        response.restrictedFunctions = data?.restrictedFunctions?.map(
          mapper.UserMapperService.parseRestrictedFunctionDtoToRestrictionFunction
        ) || [];
        return response;
      }),
      catchError(() => of(new contracts.GetUserRestrictedFunctionsResponse()))
    );
  }

  setUserMenuPinPreference(
    request: contracts.SetUserMenuPinRequest
  ): Observable<contracts.SetUserMenuPinResponse> {
    this.cookieService.put('JetStudio.menuPinPreference', request.menuPin + '');

    return of(new contracts.SetUserMenuPinResponse());
  }

  signOut(request: contracts.SignOutRequest): Observable<contracts.SignOutResponse> {
    // ToDo Remove after implementing auth0 authorization in production
    if (helpers.isLegacyAuth() && !request.isLegacyAuthInterceptorError) {
      localStorage.removeItem('isLegacy');
    }

    const host = location.host.split('.');
    if (host.length > 2) {
      let i = 2;
      while (i < host.length) {
        const subdomain = '.' + location.host.split('.').splice(-i, i).join('.');
        i++;
        this.cookieService.removeAll({ domain: subdomain });
      }
    }
    this.cookieService.removeAll();
    return of(new contracts.SignOutResponse());
  }
}
