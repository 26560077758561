import { createSelector } from 'reselect';

import * as fromCore from '../reducers';
import * as models from '../models';

import { userFeatureKey } from '../reducers/user.reducer';

export const getUserState = createSelector(fromCore.getCoreState, (state) => state[userFeatureKey]);
export const getUserUser = createSelector(getUserState, (state) => state.user);
export const getUserUserIsAccessDenied = createSelector(
  getUserState,
  (state) => state.isAccessDenied
);
export const getUserUserRoleIdList = createSelector(getUserState, (state) => {
  if (state.user === null) {
    return [];
  }

  return state.user.roles.map((item: models.Role) => item.id);
});
export const getUserUserViewPermissions = createSelector(getUserState, (state) => {
  if (state.user === null) {
    return {};
  }

  return state.user.viewPermissions;
});
export const getUserRestrictedFunctions = createSelector(getUserState, (state) => {
  if (state.user === null) {
    return [];
  }
  return state.user.restrictedFunctions;
});
