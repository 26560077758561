<!-- START FOOTER -->
<div class="container-fluid container-fixed-lg footer">
  <div class="copyright sm-text-center">
    <p class="small no-margin pull-left sm-pull-reset">
      <span class="hint-text">Copyright © {{ currentYear }}</span>
      <span class="font-montserrat"> XO Global LLC. </span>
      <span class="hint-text">All rights reserved.</span>
    </p>
  </div>
</div>
<!-- END FOOTER -->
