import * as models from '../../../models';

import * as _ from 'lodash';

export class UserMapperService {
  static mapUserDtoToInternal(svcJsonResp: any): models.User {
    const user = new models.User();
    user.firstName = svcJsonResp.firstName;
    user.lastName = svcJsonResp.lastName;
    user.id = svcJsonResp.userId;
    user.userTypeId = svcJsonResp.userTypeId;
    user.emailAddress = svcJsonResp.emailAddress;

    const organization = new models.Organization();
    organization.id = Number(svcJsonResp.organizationId);
    user.organization = organization;

    user.viewPermissions = svcJsonResp.permissions?.length
      ? svcJsonResp.permissions.reduce((acc, permissionKey) => {
          _.set(acc, permissionKey, true);
          return acc;
        }, {}) : {};

    return user;
  }

  static parseRestrictedFunctionDtoToRestrictionFunction(item: any): models.RestrictedFunction {
    const restrictedObj = new models.RestrictedFunction();
    restrictedObj.description = item.description;
    restrictedObj.restrictedFunctionId = item.restrictedFunctionId;
    restrictedObj.restrictedFunctionName = item.restrictedFunctionName;
    restrictedObj.securityAccess = item.securityAccess;

    return restrictedObj;
  }
}
