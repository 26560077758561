import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as userServiceContracts from '../services/user/contracts';
import * as actions from '../actions/user.actions';
import * as layoutActions from '../../shared/actions/layout.actions';

import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as models from '../models';
import * as _ from 'lodash';

@Injectable()
export class UserEffects {
  loadUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.load),
      switchMap(() => {
        const request = new userServiceContracts.GetUserRequest();

        return combineLatest([
          this.userService.getUser(request),
          this.userService.getUserRestrictedFunctions()
        ]).pipe(
          switchMap(([userResp, restrictedFunctionsResponse]) => {
            const user: models.User = _.cloneDeep(userResp.entity);
            user.restrictedFunctions = restrictedFunctionsResponse.restrictedFunctions;
            return [
              actions.loadSuccess({ payload: user }),
              layoutActions.setServerEnvironment({ payload: userResp.environment }),
              layoutActions.setMenuPin({ payload: userResp.menuPin }),
            ];
          })
        );
      })
    )
  );

  signOut$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.signOut),
      switchMap(() => {
        return this.userService.signOut(new userServiceContracts.SignOutRequest()).pipe(
          map(() => {
            location.reload();

            return actions.empty();
          })
        );
      })
    )
  );

  setMenuPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setMenuPinPreference),
      map((action) => action.payload),
      switchMap((payload) => {
        const req = new userServiceContracts.SetUserMenuPinRequest();
        req.menuPin = payload;
        return this.userService.setUserMenuPinPreference(req).pipe(
          map(() => {
            return actions.empty();
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    @Inject(userServiceContracts.USER_SERVICE_TOKEN)
    private userService: userServiceContracts.IUserService
  ) {}
}
