import { BrowserModule } from '@angular/platform-browser';
import { CookieModule, CookieService } from 'ngx-cookie';
import {
  APP_INITIALIZER,
  ElementRef,
  InjectionToken,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  HttpErrorResponse,
} from '@angular/common/http';

import { Store, StoreModule, select, ActionReducerMap } from '@ngrx/store';
import { StoreRouterConnectingModule, RouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppRoutingModule } from './app-routing.module';

import * as coreContainers from './core/containers';
import * as coreGuards from './core/guards';
import * as coreResolvers from './core/resolver';
import * as userActions from './core/actions/user.actions';
import * as coreEffects from './core/effects';
import * as coreTypes from './core/types';
import * as helpers from './core/helpers';

import * as sharedSystemActions from './shared/actions/system.actions';
import * as sharedLayoutActions from './shared/actions/layout.actions';
import * as sharedGuards from './shared/guards';
import * as coreSelectors from './core/selectors';
import * as sharedTypes from './shared/types';
import { SharedModule } from './shared/shared.module';

import * as configServiceContracts from './core/services/config/contracts';
import * as userServiceContracts from './core/services/user/contracts';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { EnvironmentEnum } from '../environments/environment.interface';

import { CustomSerializer, metaReducers, reducers, State } from './reducers';
import { CoreModule } from './core/core.module';

import { filter, first } from 'rxjs/operators';
import { EffectsModule } from '@ngrx/effects';
import { Apollo, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { NotificationModule, NOTIFICATION_CONTAINER } from '@progress/kendo-angular-notification';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';

interface Window {
  chrome: any;
  location: any;
}

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('Registered Reducers');

export function getReducers() {
  return reducers;
}

declare let window: Window;

@NgModule({
  declarations: [],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CookieModule.forRoot(),
    CoreModule,
    FormsModule,
    NotificationModule,
    HttpClientModule,
    // NvD3Module,
    ReactiveFormsModule,
    SharedModule,
    // StoreModule.forRoot(REDUCER_TOKEN, { metaReducers }),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 15, // set maximum stored states
      logOnly: environment.environment === EnvironmentEnum.PRODUCTION, // Restrict extension to log-only mode in prod
    }),
    EffectsModule.forRoot([coreEffects.UserEffects, coreEffects.Auth0ErrorNotificationEffects]),
  ],
  providers: [
    {
      provide: NOTIFICATION_CONTAINER,
      useFactory: () => {
        return { nativeElement: document.body } as ElementRef;
      },
    },
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer,
    },
    {
      provide: sharedTypes.BaseHttp,
      useFactory: baseHttpServiceFactory,
      deps: [HttpClient, configServiceContracts.SERVICE_TOKEN, Injector],
    },
    // ToDo Update after implementing auth0 authorization in production
    {
      provide: HTTP_INTERCEPTORS,
      useClass: sharedTypes.CustomAuthInterceptor,
      multi: true,
    },
    {
      provide: REDUCER_TOKEN,
      useFactory: getReducers,
    },
    {
      provide: configServiceContracts.SERVICE_TOKEN,
      useFactory: configServiceFactory,
      deps: [CookieService],
    },
    {
      provide: APP_INITIALIZER,
      // ToDo Update after implementing auth0 authorization in production
      useFactory: helpers.isLegacyAuth() ? configServiceInitializer : auth0ConfigServiceInitializer,
      deps: [configServiceContracts.SERVICE_TOKEN, Store, CookieService],
      multi: true,
    },
    {
      provide: userServiceContracts.USER_SERVICE_TOKEN,
      useFactory: userServiceFactory,
      deps: [HttpClient, Apollo, CookieService, configServiceContracts.SERVICE_TOKEN],
    },
    {
      provide: sharedTypes.NOTIFICATION_SERVICE_TOKEN,
      useClass: sharedTypes.NotificationService,
    },
    {
      provide: sharedTypes.STOMP_SERVICE_TOKEN,
      useClass: sharedTypes.StompService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US',
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApollo.bind(this, 'SourcingApiServiceEndpoint'),
      deps: [HttpLink, configServiceContracts.SERVICE_TOKEN, Store],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: provideNamedApollo.bind(this, [
        { endpointName: 'AircraftServiceEndpoint', apolloName: 'aircraftApollo' },
        { endpointName: 'OperatorApiServiceEndpoint', apolloName: 'operatorApollo' },
        { endpointName: 'EmailServiceEndpoint', apolloName: 'emailApollo' },
      ]),
      deps: [HttpLink, configServiceContracts.SERVICE_TOKEN, Store],
    },
    coreResolvers.LandingResolver,
    coreResolvers.ConfigServiceInitializerResolver,
    coreGuards.AuthorizationGuard,
    coreGuards.AuthorizationGuestGuard,
    coreGuards.AuthorizationUserGuard,
    coreGuards.PermissionGuard,
    coreGuards.DebugGuard,
    sharedGuards.DebugGuard,
  ],
  bootstrap: [coreContainers.AppComponent],
})
export class AppModule {}

export function baseHttpServiceFactory(
  http: HttpClient,
  configService: coreTypes.IConfigService,
  injector: Injector
): sharedTypes.BaseHttp {
  return new sharedTypes.BaseHttp(configService, http, injector);
}

export function configServiceFactory(cookieService: CookieService): any {
  return environment.environment === EnvironmentEnum.PRODUCTION
    ? new coreTypes.ConfigService(cookieService)
    : new coreTypes.ConfigMockService();
}

// ToDo Update after implementing auth0 authorization in production
export function auth0ConfigServiceInitializer(
  configService: configServiceContracts.IConfigService,
  store$: Store<any>
): Function {
  configService.load();
  return () => store$.dispatch(sharedSystemActions.setBrowserSupport({ payload: true }));
}
export function configServiceInitializer(
  configService: configServiceContracts.IConfigService,
  store$: Store<any>,
  cookieService: CookieService
): Function {
  return () => {
    if (location.pathname === '/login') {
      try {
        const params = new URL(location.href).searchParams;
        const token = params.get('token');
        const expires = params.get('expirationDate');
        const returnUrl = params.get('returnUrl');
        cookieService.put('token', token, { expires: expires });
        location.href = returnUrl || location.origin;
      } catch (e) {
        store$.dispatch(sharedLayoutActions.showError({ payload: "Couldn't login" }));
      }
    }
    configService.load();
    store$.dispatch(userActions.load());
    store$.dispatch(sharedSystemActions.setBrowserSupport({ payload: true }));

    if (!isAllowedDomain()) {
      store$.dispatch(
        sharedLayoutActions.showError({
          payload:
            'Sorry. Seems wrong App Configuration has been deployed. Please contact development team.',
        })
      );
    }
    return store$
      .pipe(
        select(coreSelectors.getUserUser),
        filter((user) => user !== null),
        first()
      )
      .toPromise();
  };
}

export function userServiceFactory(
  http: HttpClient,
  apollo: Apollo,
  cookieService: CookieService,
  configService: coreTypes.IConfigService
): coreTypes.IUserService {
  // if (environment.environment === EnvironmentEnum.PRODUCTION) {
  return new coreTypes.UserService(http, apollo, cookieService, configService);
  // } else {
  //   return new coreTypes.UserMockService(cookieService);
  // }
}

export function globalErrorHandlerFactory(
  http: HttpClient,
  configService: configServiceContracts.IConfigService,
  store: Store<any>
): sharedTypes.GlobalErrorHandler {
  const loggers: Array<sharedTypes.ILogger> = [];
  loggers.push(new sharedTypes.ErrorWebServiceLoggerBridge(http, configService));

  return new sharedTypes.GlobalErrorHandler(store, loggers);
}

export function browserSupported(): boolean {
  const isChrome: boolean = !!window.chrome;

  return isChrome;
}

export function provideNamedApollo(
  configs: Array<{ endpointName: string; apolloName: string }>,
  httpLink: HttpLink,
  configService: configServiceContracts.IConfigService,
  store: Store
) {
  return configs.reduce((acc, config) => {
    acc[config.apolloName] = provideApollo(config.endpointName, httpLink, configService, store);
    return acc;
  }, {});
}

export function provideApollo(
  endpointName: string,
  httpLink: HttpLink,
  configService: configServiceContracts.IConfigService,
  store: Store
) {
  const onErrorLink = onError(({ networkError, graphQLErrors, operation }) => {
    if (networkError && (networkError as HttpErrorResponse).status === 401) {
      location.reload();
    }
    if (graphQLErrors) {
      const definition: any = operation.query.definitions[0];
      if (definition?.operation !== 'mutation') {
        store.dispatch(sharedLayoutActions.showErrorPopup({ payload: graphQLErrors[0].message }));
      }
    }
  });

  const auth = setContext(() => ({
    headers: {
      Authorization: `Bearer ${configService.get('AuthorizationToken')}`,
    },
    uri: `${configService.get(endpointName)}/graphql`,
  }));

  const link = ApolloLink.from([onErrorLink, auth, httpLink.create({})]);
  const cache = new InMemoryCache();
  const subResult = {
    link,
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  };

  return subResult;
}

export function isAllowedDomain(): boolean {
  const hostname = window.location.hostname;
  return environment.allowedDomains.some((domain) => hostname.endsWith(domain));
}
