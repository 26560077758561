import { createSelector } from 'reselect';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromSourcingRequestList from './sourcing-request-list';
import * as fromMySourcingRequestList from './my-sourcing-request-list';
import * as fromLeadOwnerAgentCollection from './lead-owner-agent-collection';
import * as fromSourcingRequest from './sourcing-request';
import * as fromOperatorCollection from './operator-collection';
import * as fromAircraftCategoryCollection from './aircraft-category-collection';
import * as fromAircraftCollection from './aircraft-collection';
import * as fromOperatorCancellationTerms from './operator-cancellation-terms';
import * as fromFbo from './fbo';
import * as fromPodsCollection from './pods-collection';
import * as fromMemberShipStatus from './membership-status';
import * as fromCurrencyRates from './currency-rates';
import * as fromSentEmails from './sent-emails';
import * as fromLastLookRequestList from './last-look-request-list';
import * as fromLastLookRequest from './last-look-request';
import * as fromOnFleetOperatorCollection from './on-flit-operators-collection';
import * as fromOnFleetAircraftCollection from './on-flit-aircraft-collection';
import * as fromAircraftAvailability from './aircraft-availability';

export interface State {
  aircraftCategoryCollection: fromAircraftCategoryCollection.State;
  sourcingRequestList: fromSourcingRequestList.State;
  mySourcingRequestList: fromMySourcingRequestList.State;
  leadOwnerAgentCollection: fromLeadOwnerAgentCollection.State;
  sourcingRequest: fromSourcingRequest.State;
  operatorCollection: fromOperatorCollection.State;
  aircraftCollection: fromAircraftCollection.State;
  operatorCancellationTerms: fromOperatorCancellationTerms.State;
  fbo: fromFbo.State;
  podsCollection: fromPodsCollection.State;
  membershipStatus: fromMemberShipStatus.State;
  currencyRates: fromCurrencyRates.State;
  sentEmails: fromSentEmails.State;
  lastLookRequestList: fromLastLookRequestList.State;
  lastLookRequest: fromLastLookRequest.State;
  onFleetOperatorCollection: fromOnFleetOperatorCollection.State;
  onFleetAircraftCollection: fromOnFleetAircraftCollection.State;
  aircraftAvailability: fromAircraftAvailability.State;
}

export const reducers: ActionReducerMap<State> = {
  aircraftCategoryCollection: fromAircraftCategoryCollection.reducer,
  sourcingRequestList: fromSourcingRequestList.reducer,
  mySourcingRequestList: fromMySourcingRequestList.reducer,
  leadOwnerAgentCollection: fromLeadOwnerAgentCollection.reducer,
  sourcingRequest: fromSourcingRequest.reducer,
  operatorCollection: fromOperatorCollection.reducer,
  aircraftCollection: fromAircraftCollection.reducer,
  operatorCancellationTerms: fromOperatorCancellationTerms.reducer,
  fbo: fromFbo.reducer,
  podsCollection: fromPodsCollection.reducer,
  membershipStatus: fromMemberShipStatus.reducer,
  currencyRates: fromCurrencyRates.reducer,
  sentEmails: fromSentEmails.reducer,
  lastLookRequestList: fromLastLookRequestList.reducer,
  lastLookRequest: fromLastLookRequest.reducer,
  onFleetOperatorCollection: fromOnFleetOperatorCollection.reducer,
  onFleetAircraftCollection: fromOnFleetAircraftCollection.reducer,
  aircraftAvailability: fromAircraftAvailability.reducer,
};

const combinedReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): State {
  return combinedReducer(state, action);
}

export const getAircraftCategoryCollectionState = (state: any) =>
  state.sourcing.aircraftCategoryCollection;
export const getAircraftCategoryCollectionLoaded = createSelector(
  getAircraftCategoryCollectionState,
  fromAircraftCategoryCollection.getLoaded
);
export const getAircraftCategoryCollectionLoading = createSelector(
  getAircraftCategoryCollectionState,
  fromAircraftCategoryCollection.getLoading
);
export const getAircraftCategoryCollection = createSelector(
  getAircraftCategoryCollectionState,
  fromAircraftCategoryCollection.getItems
);

export const getOperatorCollectionState = (state: any) => state.sourcing.operatorCollection;
export const getOperatorCollectionLoaded = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getLoaded
);
export const getOperatorCollectionLoading = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getLoading
);
export const getOperatorCollection = createSelector(
  getOperatorCollectionState,
  fromOperatorCollection.getItems
);

export const getSourcingRequestState = (state: any) => state.sourcing.sourcingRequest;
export const getSourcingRequest = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getSourcingRequest
);
export const getSourcingRequestLoaded = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getLoaded
);
export const getSourcingRequestLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getLoading
);
export const getSourcingRequestPriceBreakdown = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getPriceBreakdown
);
export const getSourcingRequestIsPriceBreakdownLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getIsPriceBreakdownLoading
);
export const getSourcingRequestIsPriceBreakdownLoaded = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getIsPriceBreakdownLoaded
);
export const getSourcingRequestTaxBreakdown = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getTaxBreakdown
);
export const getSourcingRequestIsTaxBreakdownLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getIsTaxBreakdownLoading
);
export const getSourcingRequestIsTaxBreakdownLoaded = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getIsTaxBreakdownLoaded
);
export const getSourcingRequestModeratedOffers = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getModeratedOffers
);
export const getSourcingRequestSendModeratedOffersLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getSendModeratedOffersLoading
);
export const getPurchaseOfferValidationLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getIsValidationLoading
);

export const getCreatePurchaseOfferSuccess = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getIsPriceBreakdownLoading
);

export const getSourcingRequestValidationOfferResults = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getValidationOfferResults
);

export const getPurchaseOfferCharterAgreement = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getPurchaseOfferCharterAgreement
);

export const getPurchaseOfferMarketplaceQuotes = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getMarketplaceQuotes
);

export const getPurchaseOfferMarketplaceQuotesLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getMarketplaceQuotesLoading
);

export const getPurchaseOfferMarketplaceQuotesLoaded = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getMarketplaceQuotesLoaded
);

export const getPurchaseOfferElacPriceInfo = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getElacPriceInfo
);

export const getPurchaseOfferElacPriceInfoLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getElacPriceInfoLoading
);

export const getPurchaseOfferElacPriceInfoLoaded = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getElacPriceInfoLoaded
);

export const getPurchaseOfferElacPriceInfoFailed = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getElacPriceInfoFailed
);
export const getSourcingRequestOfferListLoading = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getOfferListLoading
);

export const getSourcingRequestModeratedLegs = createSelector(
  getSourcingRequestState,
  fromSourcingRequest.getModeratedLegs
);

export const getSourcingRequestListState = (state: any) => state.sourcing.sourcingRequestList;
export const getSourcingRequestListCurrentPage = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getCurrentPage
);
export const getSourcingRequestListIsLoaded = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getIsLoaded
);
export const getSourcingRequestListIsLoading = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getIsLoading
);
export const getSourcingRequestListSourcingRequestLookup = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getCriteria
);
export const getSourcingRequestListItems = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getItems
);
export const getSourcingRequestListItemsPerPage = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getItemsPerPage
);
export const getSourcingRequestListItemsTotalCount = createSelector(
  getSourcingRequestListState,
  fromSourcingRequestList.getItemsTotalCount
);

export const getMySourcingRequestListState = (state: any) => state.sourcing.mySourcingRequestList;
export const getMySourcingRequestListIsLoaded = createSelector(
  getMySourcingRequestListState,
  fromMySourcingRequestList.getIsLoaded
);
export const getMySourcingRequestListIsLoading = createSelector(
  getMySourcingRequestListState,
  fromMySourcingRequestList.getIsLoading
);
export const getMySourcingRequestListSourcingRequestLookup = createSelector(
  getMySourcingRequestListState,
  fromMySourcingRequestList.getCriteria
);
export const getMySourcingRequestListItems = createSelector(
  getMySourcingRequestListState,
  fromMySourcingRequestList.getItems
);

export const getLeadOwnerAgentCollectionState = (state: any) => state.sourcing.leadOwnerAgentCollection;
export const getLeadOwnerAgentCollectionLoaded = createSelector(
  getLeadOwnerAgentCollectionState,
  fromLeadOwnerAgentCollection.getLoaded
);
export const getLeadOwnerAgentCollectionLoading = createSelector(
  getLeadOwnerAgentCollectionState,
  fromLeadOwnerAgentCollection.getLoading
);
export const getLeadOwnerAgentCollection = createSelector(
  getLeadOwnerAgentCollectionState,
  fromLeadOwnerAgentCollection.getItems
);

export const getAircraftCollectionState = (state: any) => state.sourcing.aircraftCollection;
export const getAircraftCollectionIsLoaded = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getIsLoaded
);
export const getAircraftCollectionIsLoading = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getIsLoading
);
export const getAircraftCollectionItems = createSelector(
  getAircraftCollectionState,
  fromAircraftCollection.getItems
);

export const getOperatorTermsState = (state: any) => state.sourcing.operatorCancellationTerms;
export const getOperatorTermsIsLoaded = createSelector(
  getOperatorTermsState,
  fromOperatorCancellationTerms.getIsLoaded
);
export const getOperatorTermsIsLoading = createSelector(
  getOperatorTermsState,
  fromOperatorCancellationTerms.getIsLoading
);
export const getOperatorTerms = createSelector(
  getOperatorTermsState,
  fromOperatorCancellationTerms.getTerms
);

export const getFboState = (state: any) => state.sourcing.fbo;
export const getGetFboMap = createSelector(getFboState, fromFbo.getFboMap);
export const getFboLoading = createSelector(getFboState, fromFbo.getFboLoading);

export const getPodsCollectionState = (state: any) => state.sourcing.podsCollection;
export const getPodsCollectionLoaded = createSelector(
  getPodsCollectionState,
  fromPodsCollection.getLoaded
);
export const getPodsCollectionLoading = createSelector(
  getPodsCollectionState,
  fromPodsCollection.getLoading
);
export const getPodsCollection = createSelector(
  getPodsCollectionState,
  fromPodsCollection.getItems
);

export const getMembershipStatusState = (state: any) => state.sourcing.membershipStatus;
export const getMembershipStatusLoaded = createSelector(
  getMembershipStatusState,
  fromMemberShipStatus.getLoaded
);
export const getMembershipStatus = createSelector(
  getMembershipStatusState,
  fromMemberShipStatus.getMembershipStatus
);

export const getCurrencyRatesState = (state: any) => state.sourcing.currencyRates;
export const getCurrencyRatesLoaded = createSelector(getCurrencyRatesState, fromCurrencyRates.getLoaded);
export const getCurrencyRatesLoading = createSelector(getCurrencyRatesState, fromCurrencyRates.getLoading);
export const getCurrencyRates = createSelector(getCurrencyRatesState, fromCurrencyRates.getItems);

export const getSentEmailsState = (state: any) => state.sourcing.sentEmails;
export const getSentEmailsLoaded = createSelector(getSentEmailsState, fromSentEmails.getLoaded);
export const getSentEmailsLoading = createSelector(getSentEmailsState, fromSentEmails.getLoading);
export const getSentEmailsItems = createSelector(getSentEmailsState, fromSentEmails.getItems);
export const getEmailBodyLoaded = createSelector(getSentEmailsState, fromSentEmails.getEmailBodyLoaded);
export const getEmailBodyLoading = createSelector(getSentEmailsState, fromSentEmails.getEmailBodyLoading);
export const getEmailBody = createSelector(getSentEmailsState, fromSentEmails.getEmailBody);


export const getLastLookRequestListState = (state: any) => state.sourcing.lastLookRequestList;
export const getLastLookRequestListCurrentPage = createSelector(
  getLastLookRequestListState,
  fromLastLookRequestList.getCurrentPage
);
export const getLastLookRequestListIsLoaded = createSelector(
  getLastLookRequestListState,
  fromLastLookRequestList.getIsLoaded
);
export const getLastLookRequestListIsLoading = createSelector(
  getLastLookRequestListState,
  fromLastLookRequestList.getIsLoading
);
export const getLastLookRequestListItems = createSelector(
  getLastLookRequestListState,
  fromLastLookRequestList.getItems
);
export const getLastLookRequestListItemsPerPage = createSelector(
  getLastLookRequestListState,
  fromLastLookRequestList.getItemsPerPage
);
export const getLastLookRequestListItemsTotalCount = createSelector(
  getLastLookRequestListState,
  fromLastLookRequestList.getItemsTotalCount
);

export const getLastLookRequestState = (state: any) => state.sourcing.lastLookRequest;
export const getLastLookRequest = createSelector(
  getLastLookRequestState,
  fromLastLookRequest.getLastLookRequest
);
export const getLastLookRequestLoaded = createSelector(
  getLastLookRequestState,
  fromLastLookRequest.getLoaded
);
export const getLastLookRequestLoading = createSelector(
  getLastLookRequestState,
  fromLastLookRequest.getLoading
);

export const getOnFleetOperatorCollectionState = (state: any) => state.sourcing.onFleetOperatorCollection;
export const getOnFleetOperatorCollectionIsLoaded = createSelector(
  getOnFleetOperatorCollectionState,
  fromOnFleetOperatorCollection.getIsLoaded
);
export const getOnFleetOperatorCollectionIsLoading = createSelector(
  getOnFleetOperatorCollectionState,
  fromOnFleetOperatorCollection.getIsLoading
);
export const getOnFleetOperatorCollectionItems = createSelector(
  getOnFleetOperatorCollectionState,
  fromOnFleetOperatorCollection.getItems
);

export const getOnFleetAircraftCollectionState = (state: any) => state.sourcing.onFleetAircraftCollection;
export const getOnFleetAircraftCollectionIsLoaded = createSelector(
  getOnFleetAircraftCollectionState,
  fromOnFleetAircraftCollection.getIsLoaded
);
export const getOnFleetAircraftCollectionIsLoading = createSelector(
  getOnFleetAircraftCollectionState,
  fromOnFleetAircraftCollection.getIsLoading
);
export const getOnFleetAircraftCollectionItems = createSelector(
  getOnFleetAircraftCollectionState,
  fromOnFleetAircraftCollection.getItems
);


export const getAircraftAvailabilityState = (state: any) => state.sourcing.aircraftAvailability;
export const getCamberOptionsIsLoaded = createSelector(
  getAircraftAvailabilityState,
  fromAircraftAvailability.getCamberOptionsLoaded
);
export const getCamberOptionsIsLoading = createSelector(
  getAircraftAvailabilityState,
  fromAircraftAvailability.getCamberOptionsLoading
);

export const getCamberOptions = createSelector(
  getAircraftAvailabilityState,
  fromAircraftAvailability.getCamberOptions
);
export const getMarketplaceOptionsIsLoaded = createSelector(
  getAircraftAvailabilityState,
  fromAircraftAvailability.getMarketplaceOptionsLoaded
);
export const getMarketplaceOptionsIsLoading = createSelector(
  getAircraftAvailabilityState,
  fromAircraftAvailability.getMarketplaceOptionsLoading
);

export const getMarketplaceOptions = createSelector(
  getAircraftAvailabilityState,
  fromAircraftAvailability.getMarketplaceOptions
);