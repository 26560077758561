import { SortDescriptor } from '@progress/kendo-data-query';
import { LookupWithOperator } from './lookup-with-operator';

export class AircraftLookup extends LookupWithOperator {
  insApproveOnly: boolean = null;
  completed: boolean = null;
  approvedBy: string = null;
  aircraftCategory: number = null;
  homeBaseCountry: string = null;
  homeBaseServiceAreas: Array<string> = [];
  onFleet: boolean = null;
  homeBase: string = null;
  active: boolean = true;
  d085OpsSpecsApproval: boolean = null;
  marketplacePhotosApproval: boolean = null;
  aircraftAge: number = null;
  refurbishmentAge: number = null;
  sort: Array<SortDescriptor> = [{field: 'tailNumber', dir: 'asc'}];

  constructor(data?: any) {
    super(data);
    if (data) {
      this.insApproveOnly = this.parseBoolean(data.insApproveOnly);
      this.completed = this.parseBoolean(data.completed);
      this.approvedBy = this.parseString(data.approvedBy);
      this.aircraftCategory = this.parseNumber(data.aircraftCategory);
      this.homeBaseCountry = this.parseString(data.homeBaseCountry);
      this.homeBaseServiceAreas = this.parseArray<string>(data.homeBaseServiceAreas);
      this.onFleet = this.parseBoolean(data.onFleet);
      this.homeBase = this.parseString(data.homeBase);
      this.active = this.parseBoolean(data.active) ?? true;
      this.d085OpsSpecsApproval = this.parseBoolean(data.d085OpsSpecsApproval);
      this.marketplacePhotosApproval = this.parseBoolean(data.marketplacePhotosApproval);
      this.aircraftAge = this.parseNumber(data.aircraftAge);
      this.refurbishmentAge = this.parseNumber(data.refurbishmentAge);
    }
  }
}
