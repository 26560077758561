import { EnvironmentEnum, IAuth, IEnvironment } from "./environment.interface";

const authConfig: IAuth = {
  domain: 'https://auth0.prod.ea.vistajet.com',
  clientId: 'jpKowz30AuZ5mAHoLpTVVZXjEAlhGOzK',
  audience: 'Jetstudio',
  errorPath: '/error',
  connection: 'XOAzure',
};

const { domain, clientId, audience, errorPath, connection } = authConfig;

export const environment: IEnvironment = {
  auth: {
    domain,
    clientId,
    audience,
    errorPath
  },
  httpInterceptor: {
    allowedList: [`*`],
  },
  environment: EnvironmentEnum.PRODUCTION,
  aircraftServiceEndpoint: 'https://aircraft.mp.prod.jetsmarter.io',
  bookingApiServiceEndpoint: 'https://api.jetsmarter.io/booking/v1',
  charterPricingAnalyticsEndpoint: 'https://api.jetsmarter.io/charter-pricing-analytics',
  charterPricingAnalyticsEndpointV1: 'https://pricing.jetsmarter.io/api/v1/charter-pricing-analytics-backend-api',
  charterPricingServiceEndpoint: 'https://api.jetsmarter.io/charter-pricing',
  charterPricingServiceEndpointV1: 'https://pricing.jetsmarter.io/api/v1/charter-pricing-backend-api',
  flightPreviewOptionsEndpoint: 'https://pricing.jetsmarter.io/api/v1/charter-pricing-backend-api/quotes/mp',
  charterSalesServiceEndpoint: 'https://api.jetsmarter.io/charter-sales',
  clientAppEndpoint: 'https://flyxo.com/profile/requests',
  clientSearchEndpoint: 'https://flyxo.com/search/points',
  clientMembershipStatusEndpoint: 'https://api.flyxo.com/getClientMembershipStatus',
  clientOperatorServiceEndpoint: 'https://operator-service-op.jetsmarter.com',
  clientServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  consoleAppEndpoint: 'https://console.vista.studio',
  customShuttleServiceEndpoint: 'https://erp-service-op.jetsmarter.com',
  cyoPricingServiceEndpoint: 'https://api.jetsmarter.io/cyo-pricing-mgmt/v1',
  emailServiceEndpoint: 'https://email-service.mp.prod.jetsmarter.io',
  erpServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  flightAnalyticsServiceEndpoint: 'https://api.jetsmarter.io/flight-analytics/v1',
  invoiceAppEndpoint: 'http://jetsmarter.com',
  invoiceServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  jetPulseServiceEndpoint: 'https://jetpulse-api.jetsmarter.com',
  jetSalesServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  legServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  marketplaceServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  membershipServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  offerServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  operatorAnalyticsApiServiceEndpoint: 'https://api.jetsmarter.io/operator-analytics',
  operatorApiServiceEndpoint: 'https://operator.mp.prod.jetsmarter.io',
  operatorPlatformApiServiceEndpoint: 'https://operator.flyxo.com',
  operatorServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  orderServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  productServiceEndpoint: 'https://jet-sales.flyxo.com/jet-sales',
  routeGroupServiceEndpoint: 'https://operator-service-op.jetsmarter.com',
  routeManagementServiceEndpoint: 'https://api.jetsmarter.io/route-mgmt',
  seatChartsUrl: 'https://app.jetsmarter.io/seat-pricing-engine-chart/shuttle_demand_price',
  seatPricingServiceEndpoint: 'https://api.jetsmarter.io/seat-pricing-mgmt/v1',
  sourcingApiServiceEndpoint: 'https://sourcing.mp.prod.jetsmarter.io',
  taskApiServiceEndpoint: 'https://api.jetsmarter.io/task',
  serverEnvironment: 'prod',
  version: '2.3.0',
  taskManagerDebounceTime: 50,
  masterApiEndpoint: null,
  allowedDomains: ['jetsmarter.com', 'jetsmarter.io', 'requests.vista.studio'],
  identityProvider: 'https://identity-op.jetsmarter.com',
  rmDashboardExternalUrl: 'https://v1sta.tech',
};
