import { CompositeFilterDescriptor, SortDescriptor } from "@progress/kendo-data-query";
import { Lookup } from '../../../../shared/models';

export class VendorOffersLookup extends Lookup {
    sort: Array<SortDescriptor> = [{field: 'createdDate', dir: 'desc'}];
    filter: CompositeFilterDescriptor = null;
    vendorId: string = null;

    constructor(data?: any) {
      super(data);
      if (data) {
        this.vendorId = this.parseString(data.vendorId);
      }
    }
  }
  