import { AircraftImageTypeEnum } from './aircraft-image-type-enum';
import { ImageSourceTypeEnum } from './image-source-type-enum';
import { GenericFloorPlan } from './generic-floor-plan';

export class AircraftImage {
  id: number;
  sortOrder: number;
  url: string = null;
  thumbnailUrl: string = null;
  s3url: string = null;
  type: AircraftImageTypeEnum;
  width: number;
  height: number;
  sourceType: ImageSourceTypeEnum;
  genericFloorPlan: GenericFloorPlan;
  genericFloorPlanId: number;
}
