import * as models from '../../domain/models';

export class FlightRequestCreateForm {
  aircraftCategories: Array<models.AircraftCategory> = [];
  legs: Array<models.LegRequest> = [];
  client: models.Client;
  specialRequest: string;
  addDedicatedFleetOptions = true;
  sendToXOMarketplace = true;
  allowFirmDeparture = true;
  sendOfferAutomatically = false;
  elacProForma = false;
  petOnBoard = false;
  petNote: string;
  sfOpportunityId: string;
  travelReason: string;
  specialRequirements: Array<string>;

  isValid(): boolean {
    return !!this.aircraftCategories?.length && !!this.client && !!this.legs.length &&
      !(this.legs.length === 1 && this.legs[0].getTechStop()) &&
      !(this.legs[this.legs.length - 1].getTechStop());
  }

  addLegRequest(legRequest: models.LegRequest): void {
    this.legs.push(legRequest);
  }

  removeLegRequestAt(index: number): void {
    this.legs.splice(index, 1);
  }

  updateLegRequest(index: number, legRequest: models.LegRequest): void {
    this.legs[index] = legRequest;
  }

  getLegs(): Array<models.LegRequest> {
    return this.legs;
  }

  setLegs(legs: Array<models.LegRequest>): FlightRequestCreateForm {
    this.legs = legs;

    return this;
  }

  setClient(client: models.Client): FlightRequestCreateForm {
    this.client = client;

    return this;
  }

  getClient(): models.Client {
    return this.client;
  }

  setSpecialRequest(request: string): FlightRequestCreateForm {
    this.specialRequest = request;

    return this;
  }

  getSpecialRequest(): string {
    return this.specialRequest;
  }

  setAddDedicatedFleetOptions(value: boolean): FlightRequestCreateForm {
    this.addDedicatedFleetOptions = value;

    return this;
  }

  getAddDedicatedFleetOptions(): boolean {
    return this.addDedicatedFleetOptions;
  }

  setSendToXOMarketplace(value: boolean): FlightRequestCreateForm {
    this.sendToXOMarketplace = value;

    return this;
  }

  getSendToXOMarketplace(): boolean {
    return this.sendToXOMarketplace;
  }

  setAllowFirmDeparture(value: boolean): FlightRequestCreateForm {
    this.allowFirmDeparture = value;

    return this;
  }

  getAllowFirmDeparture(): boolean {
    return this.allowFirmDeparture;
  }

  setSendOfferAutomatically(value: boolean): FlightRequestCreateForm {
    this.sendOfferAutomatically = value;

    return this;
  }

  getSendOfferAutomatically(): boolean {
    return this.sendOfferAutomatically;
  }

  setElacProForma(elacProForma: boolean): FlightRequestCreateForm {
    this.elacProForma = elacProForma;

    return this;
  }

  getElacProForma(): boolean {
    return this.elacProForma;
  }

  setPetOnBoard(petOnBoard: boolean): FlightRequestCreateForm {
    this.petOnBoard = petOnBoard;

    return this;
  }

  getPetOnBoard(): boolean {
    return this.petOnBoard;
  }

  setPetNote(petNote: string): FlightRequestCreateForm {
    this.petNote = petNote;

    return this;
  }

  getPetNote(): string {
    return this.petNote;
  }

  setSFOpportunityId(sfOpportunityId: string): FlightRequestCreateForm {
    this.sfOpportunityId = sfOpportunityId;

    return this;
  }

  getSFOpportunityId(): string {
    return this.sfOpportunityId;
  }

  setTravelReason(travelReason: string): FlightRequestCreateForm {
    this.travelReason = travelReason;

    return this;
  }

  getTravelReason(): string {
    return this.travelReason;
  }

  setSpecialRequirements(specialRequirements: Array<string>): FlightRequestCreateForm {
    this.specialRequirements = specialRequirements;

    return this;
  }

  getSpecialRequirements(): Array<string> {
    return this.specialRequirements;
  }

  setAircraftCategories(aircraftCategories: Array<models.AircraftCategory>): FlightRequestCreateForm {
    this.aircraftCategories = aircraftCategories;

    return this;
  }

  getAircraftCategories(): Array<models.AircraftCategory> {
    return this.aircraftCategories;
  }
}
