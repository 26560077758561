import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD = '[RM OP User Detail] Load';
export const LOAD_SUCCESS = '[RM OP User Detail] Load Success';
export const LOAD_FAIL = '[RM OP User Detail] Load Fail';
export const RESET = '[RM OP User Detail] Reset';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class LoadPayload {
  constructor(public id: number) {}
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {}
}

export class LoadSuccessPayload {
  constructor(public item: models.OPUser) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions =
  | ResetAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction;
