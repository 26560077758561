import { SortDescriptor } from '@progress/kendo-data-query';
import { Lookup } from '../../../../shared/models';

export class ConfigsLookup extends Lookup {
  sort: Array<SortDescriptor> = [{field: 'createTime', dir: 'desc'}];

  constructor(data?: any) {
    super(data);
  }
 }
