export const response = {
  userId: 2,
  firstName: 'Oleg',
  lastName: 'Solovey',
  email: 'test.xojet@xojet.com',
  emailAddress: 'test.xojet@xojet.com',
  organizationId: 1,
  userTypeId: 17,
  logoutUrl: '/sp/ssout',
  environment: 'dev',
  restrictedFunctions: [
    {
      description: null,
      restrictedFunctionId: 2,
      restrictedFunctionName: 'Wire Received',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 4,
      restrictedFunctionName: 'NewOrderMembership',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 5,
      restrictedFunctionName: 'NewOrderService',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 6,
      restrictedFunctionName: 'NewOrderConcierge',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 7,
      restrictedFunctionName: 'NewOrderCharter',
      securityAccess: 1,
    },
    {
      description: null,
      restrictedFunctionId: 8,
      restrictedFunctionName: 'AmendChargesButton',
      securityAccess: 1,
    },
    {
      description: 'Access to Actions -> AR Payment Confirmed menu on the Order screen',
      restrictedFunctionId: 10,
      restrictedFunctionName: 'AR Payment Confirmed Order',
      securityAccess: 1,
    },
    {
      description: "'Revenue Management' mode of Pricing Calculator ",
      restrictedFunctionId: 11,
      restrictedFunctionName: 'RM Pricing Calculator',
      securityAccess: 1,
    },
    {
      description: 'Access to Actions -> Charter Credit Memo menu on the Order screen',
      restrictedFunctionId: 14,
      restrictedFunctionName: 'Charter Credit Memo',
      securityAccess: 1,
    },
  ],
  permissions: [
    'CAN_UPDATE_OWN_OPERATOR_USERS',
    'yieldMgmtModule.cyoPricing',
    'clientsModule.lookup',
    'yieldMgmtModule.seatPricing',
    'resourceModule.vendors',
    'resourceModule.areas',
    'CAN_READ_OWN_USER',
    'CAN_RUN_REPORTS',
    'CAN_READ_CHANGE_LOGS',
    'clientsModule.universalSearch',
    'charterSalesModule.overall',
    'sourcingModule.new',
    'CAN_READ_ALL_USERS',
    'CAN_UPDATE_ALL_USERS',
    'resourceModule.aircraft.modify',
    'resourceModule.vendors.modify',
    'operatorPlatform.actOnBehalfOfAnyOperator',
    'sourcingModule.overall',
    'CAN_UPDATE_OWN_USER',
    'marketplaceModule.oneWayQuotes',
    'resourceModule.applicationConfigs',
    'clientsModule.overall',
    'CAN_READ_ALL_OPERATORS',
    'yieldMgmtModule.rmnDashboard',
    'CAN_UPDATE_ALL_OPERATORS',
    'yieldMgmtModule.charterPricing',
    'CAN_READ_WYVERN_AIRCRAFT',
    'userMgmtModule.overall',
    'sourcingModule.requests',
    'CAN_DO_SUPER_ADMIN_ACTIONS',
    'CAN_READ_OPERATOR_ORG',
    'charterSalesModule.myOpportunities',
    'taskMgmtModule.tasks',
    'charterSalesModule.newOpportunities',
    'charterSalesModule.opportunities',
    'resourceModule.markets',
    'resourceModule.regions',
    'explorerModule.search',
    'CAN_UPDATE_OPERATOR_DOCS',
    'CAN_READ_OWN_OPERATOR',
    'yieldMgmtModule.charts',
    'CAN_READ_OWN_OPERATOR_USERS',
    'CAN_UPDATE_OPERATOR_ORG',
    'CAN_UPDATE_APP_CONFIGS',
    'taskMgmtModule.overall',
    'userMgmtModule.users',
    'resourceModule.aircraft',
    'resourceModule.airports',
    'resourceModule.overall',
    'marketplaceModule.overall',
    'CAN_READ_APP_CONFIG',
    'resourceModule.users',
    'CAN_READ_APP_CONFIGS',
    'CAN_UPDATE_OWN_OPERATOR',
    'CAN_RUN_WYVERN_IMPORT',
    'resourceModule.users.modify',
    'sourcingModule.myRequests',
    'CAN_READ_BASIC_DATA',
    'yieldMgmtModule.seatCharts',
  ],
};
