import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as selectors from '../selectors';
import * as models from '../models';

import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class LandingResolver implements Resolve<boolean> {
  constructor(private store$: Store<any>,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(selectors.getUserUserViewPermissions),
      map((viewPermissions: models.IPermissions) => {
        if (viewPermissions === null) {
          return true;
        }
        if (_.get(route, 'data.viewPermission', false)) {
          const perm = route.data.viewPermission;
          if (!_.get(viewPermissions, perm, false)) {
            return true;
          }
        }

        const redirectUrl = _.get(route, 'data.redirect', null);
        if (redirectUrl !== null) {
          // Redirect
          this.router.navigate([redirectUrl]);
        }
        return true;
      }),
      first()
    );
  }
}
